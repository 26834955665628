import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayname' })
export class DayName implements PipeTransform {
  transform(value: string): String {
    
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const d = new Date(value);
    const utcDay = d.getUTCDay(); // Use getUTCDay() for UTC-based calculation
    return days[utcDay];
  }
}


